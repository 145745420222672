html,
body {
    padding: 0;
    margin: 0;
}
header {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
}
a.MuiBottomNavigationAction-root.active {
    border: 1px solid #ddd;
    border-bottom: none;
    color: #39ac39;
    position: relative;
}
a.MuiBottomNavigationAction-root.active::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #fff;
    bottom: -1px;
}
.rst__moveHandle, .rst__loadingHandle {
    background: #39ac39 url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+) no-repeat center !important;
}